body {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  margin: 0;
  background-color: #dcdcdc;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.selectors {
  width: 150px;
}

.map-container {
  flex: 2 300px;
  position: relative;
}

.map-body {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

#aws-details-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 10px 10px 36px;
  box-sizing: border-box;
}

#aws-details {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #fff8;
  border-radius: 10px;
  background-color: #0008;
  color: #fff;
}

#aws-details .title {
  font-weight: bold;
  font-size: 1.4rem;
  padding-bottom: 1em;
}

#aws-details .section-title {
  font-weight: bold;
  font-size: 1rem;
}

#map-center-info-box {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 200px;
  height: 100px;
  margin: 10px;
  padding: 10px 20px;
  border: 2px solid #fff8;
  border-radius: 10px;
  background-color: #0008;
  color: #fff;
  font-size: 14px;
}

#map-center-info-box .title {
  font-weight: bold;
  font-size: 1rem;
}

#map-center-info-box dd {
  margin-left: 1rem;
}

#data-selector {
  position: absolute;
  top: 134px;
  right: 0px;
  width: 200px;
  height: 200px;
  margin: 10px;
  padding: 10px 20px;
  border: 2px solid #fff8;
  border-radius: 10px;
  background-color: #0008;
  color: #fff;
  font-size: 14px;
}

#aws-legend {
  position: absolute;
  bottom: 20px;
  right: 0px;
  width: 50px;
  height: 220px;
  margin: 10px;
  padding: 10px 20px;
  border: 2px solid #fff8;
  border-radius: 10px;
  background-color: #0008;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

#aws-legend .container {
  text-align: left;
}

#aws-legend .color {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #888;
  margin: 1px 0;
}

#aws-legend .threshold {
  position: relative;
  left: 10px;
  top: 6px;
}
